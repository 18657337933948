const Email = ({ width }) => {
    return (
        <div className={`icon-email ${width}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              enableBackground="new 0 0 100 100"
              version="1.1"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
            >
              <path
                d="M99.6 90.5H.2V9.7h99.4v80.8zM6.7 84h86.4V16.2H6.7V84z"
                className="st0"
                fill="currentColor"
              ></path>
              <path
                d="M49.9 54.3L1.4 15.5 5.5 10.4 49.9 45.9 94.4 10.4 98.4 15.5z"
                className="st0"
                fill="currentColor"
              ></path>
            </svg>
        </div>
    )
}

export default Email

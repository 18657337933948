import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavNursery = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.nursery}>Shop All Nursery</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.nursery_new}>Nursery New Arrivals</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-nursery-health-safety mt-2"
                data-subnav="health_safety"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="health_safety"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Health + Safety</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.health_safety === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-nursery-furniture-decor ${
                        activeSubSubnav && activeSubSubnav.health_safety
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="health_safety"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bathing}>Bathing + Grooming</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.towels}>Towels + Washcloths</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.monitors_sound_machines}>
                                Monitors + Sound Machines
                            </a>
                        </li>
                        {/* <li className="py-1 text-sm font-normal">
                            <a href={nav.health_wellness}>
                                Scales, Humidifiers, + more
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-nursery-furniture-decor mt-2"
                data-subnav="furniture_decor"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="furniture_decor"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Furniture + Decor</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.furniture_decor === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-nursery-furniture-decor ${
                        activeSubSubnav && activeSubSubnav.furniture_decor
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="furniture_decor"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.furniture}>Nursery Furniture</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.cribs_bassinets}>Cribs + Bassinets</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bedding}>Mattresses + Bedding</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.sleep_bags}>Sleep Bags + Swaddles</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_blankets}>Blankets</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.changing_pads}>Changing Pads</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-nursery-moments mt-2"
                data-subnav="nursery_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="nursery_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moment</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.nursery_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-nursery-moments ${
                        activeSubSubnav && activeSubSubnav.nursery_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="nursery_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.setting_up_nursery}>
                                Setting Up Your Nursery
                            </a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.health_safety}>Health + Safety</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_registry_essentials}>
                                Baby Registry Essentials
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bedtime_naptime}>Bedtime + Naptime</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavNursery

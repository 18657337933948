const Chat = ({ width }) => {
    return (
        <div className={`chat-icon ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 247 255.04">
              <g fill="currentColor">
                <path d="M247 255.04l-79.42-53.45H0V0h247v255.04zM16.16 185.43h156.36l58.33 39.26V16.16H16.16v169.27z"></path>
                <circle cx="123.5" cy="101.36" r="11.54"></circle>
                <circle cx="68.1" cy="101.36" r="11.54"></circle>
                <circle cx="176.6" cy="101.36" r="11.54"></circle>
              </g>
            </svg>
        </div>
    )
}

export default Chat

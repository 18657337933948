const Document = ({ width }) => {
    return (
        <div className={`document ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176.99 252">
                <g fill="#404041">
                    <path d="M176.99 252H0V0h176.99v252zM16.48 235.51H160.5V16.49H16.49v219.03z"></path>
                    <circle cx="46.4" cy="46.41" r="11.97"></circle>
                    <circle cx="46.4" cy="205.6" r="11.97"></circle>
                    <circle cx="46.4" cy="126" r="11.97"></circle>
                    <path d="M75.76 38.16H140.64V54.64999999999999H75.76z"></path>
                    <path d="M75.76 117.76H140.64V134.25H75.76z"></path>
                    <path d="M75.76 197.35H140.64V213.84H75.76z"></path>
                    <path d="M75.76 157.56H140.64V174.05H75.76z"></path>
                    <path d="M75.76 77.96H140.64V94.44999999999999H75.76z"></path>
                </g>
            </svg>
        </div>
    )
}

export default Document

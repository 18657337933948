const Calendar = ({ width }) => {
    return (
        <div className={`calendar-icon ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.91 234">
      <g fill="#404041">
        <path d="M241.91 234H0V82.52h241.91V234zM15.82 218.17h210.26V98.34H15.83v119.83z"></path>
        <path d="M241.91 98.35H0v-78h241.91v78zM15.83 82.52h210.26V36.17H15.83v46.35z"></path>
        <path d="M158.26 0H174.09V56.52H158.26z"></path>
        <path d="M67.83 0H83.66V56.52H67.83z"></path>
        <path d="M79.97 185.89H69.52v-44.56l-13.85 7.91v-9.81l16.46-9.97h7.83v56.43zM135.36 185.89H93.81v-6.81l19.94-18.84c5.14-4.99 7.99-8.39 7.99-13.45s-3.09-8.63-8.7-8.63-8.79 3.88-8.79 9.34v3.32H93.96v-3.56c0-10.45 6.96-18.52 19.07-18.52s19.31 7.36 19.31 17.81c0 8.86-5.22 14.09-12.19 20.66l-9.97 9.42h25.17v9.26zM163.61 186.84c-14.4 0-20.5-9.02-21.13-16.62H153c.63 3.24 3.8 7.28 10.6 7.28s10.52-3.01 10.52-8.07-3.32-7.83-9.1-7.83h-10.6v-9.18h10.29c4.83 0 7.76-2.45 7.76-7.12s-3.56-7.36-9.18-7.36c-6.01 0-8.63 3.48-9.26 6.89h-10.37c.24-7.52 6.49-16.06 19.55-16.06s19.78 6.25 19.78 15.83c0 6.57-4.35 10.29-7.68 11.55 4.19 1.27 9.42 5.3 9.42 13.93 0 10.05-7.75 16.78-21.13 16.78z"></path>
      </g>
    </svg>
        </div>
    )
}

export default Calendar

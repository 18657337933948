/* Base URLs */
export const c = '/collections/'
export const cap = '/collections/all-products'
export const p = '/products/'
export const pg = '/pages/'
export const a = '/account'
export const b = '/blogs/ma-edit/'
export const bt = '/blogs/ma-edit/tagged/'

/* Global Collections */

export const trunk_sale = c + 'trunk-sale'
export const sale = c + 'sale'
export const gift_cards = c + 'gift-cards'
export const toys = c + 'toys'
export const embroidery_shop = c + 'embroidery-shop'
export const matching_pajamas = c + 'matching-family-pajamas'
export const best_sellers = c + 'best-sellers'
export const bundles = c + 'bundles'
export const matching_family = c + 'mommy-me-and-daddy-me-outfits'
export const holiday = c + 'holiday'

/* Global Pages */
export const classes_page = pg + 'all-classes-events'
export const our_story = pg + 'our-story'
export const faq = pg + 'faq'
export const returns = 'https://returns.monicaandandy.com'
export const vip = pg + 'vip-gift-concierge'
export const corporate_gifting = pg + 'corporate-gifting'
export const baby_concierge = pg + 'baby-concierge'
export const shipping_page = pg + 'shipping-delivery'
export const locations = pg + 'guideshop-locations'
export const nursery_page = pg + 'shop-all-nursery-more'
export const bundles_page = pg + 'bundle-and-save'

/* Account Pages */
export const account_details = a + '?content=my-details'
export const order_history = a + '?view=order-history'
export const registry_details = a + '?content=registry-detail'
export const logout = a + '/logout'

/* New Arrivals */
export const new_arrivals = c + 'new-arrivals'
export const holiday_shop = c + 'holiday'
export const fall_shop = c + 'fall'
export const disney_holiday = c + 'disney-holiday'
export const eric_carle = c + 'eric-carle'
export const halloween = c + 'halloween'

/* Baby Clothes */
export const baby_clothes = c + 'baby-clothes'
export const baby_new_arrivals = c + 'baby-new-arrivals'
export const baby_rompers = c + 'baby-rompers-bodysuits'
export const baby_shirts = c + 'baby-shirts-tops-tshirts'
export const baby_pants = c + 'baby-pants-leggings'
export const baby_shorts = c + 'baby-shorts'
export const baby_dresses = c + 'baby-dresses'
export const baby_sweaters = c + 'baby-sweaters-sweatshirts'
export const baby_jackets = c + 'baby-jackets-outerwear'
export const baby_swimsuits = c + 'baby-swimsuits'
export const baby_swimwear = c + 'baby-swimwear'
export const baby_pajamas = c + 'baby-pajamas'
export const baby_bundles = c + 'baby-outfits-bundles'
export const outfits_bundles = c + 'outfits-bundles'
export const essential_baby_bundle = p + 'essential-baby-zipper-bundle'

/* Baby Moments */
export const baby_moments = c + 'baby-moments'
export const baby_registry = c + 'baby-registry'
export const baby_registry_essentials = c + 'baby-registry-essentials'
export const baby_nursery = c + 'baby-nursery'
export const hospital_bag = c + 'packing-your-hospital-bag'
export const baby_home = c + 'bringing-baby-home'
export const baby_first_feeds = c + 'baby-first-feeds'
export const first_feeds = c + 'first-feeds-nursing'
export const baby_sleep = c + 'baby-sleep'
export const newborn_sleep = c + 'newborn-sleep'
export const first_steps = c + 'first-steps'
export const starting_solids = c + 'starting-solids'
export const birthdays_special_occasions = c + 'birthdays-special-occasions'
export const special_occasions = c + 'special-occasions'
export const first_trips_on_the_go = c + 'first-trips-on-the-go'
export const preemie_essentials = c + 'preemie-essentials'
export const daycare_essentials = c + 'daycare-essentials'
export const welcoming_baby = c + 'welcoming-baby'
export const developmental_play = c + 'developmental-play'
export const bedtime_naptime = c + 'bedtime-naptime'
export const bathtime = c + 'bathtime'
export const on_the_go = c + 'on-the-go'
export const daycare_ready = c + 'daycare-ready'

export const baby_guide = pg + 'shop-baby'
export const baby_sets = c + 'baby-clothes?tag=evergreenbundles2024'

/* Baby Sizes */
export const baby_sizes =
    c +
    'all-products?_=pf&pf_opt_size=preemie&pf_opt_size=newborn&pf_opt_size=0-3m&pf_opt_size=3-6m&pf_opt_size=0-6m&pf_opt_size=6-9m&pf_opt_size=6-12m&pf_opt_size=9-12m&pf_opt_size=12-18m&pf_opt_size=18-24m'
export const preemie = c + 'all-products?_=pf&pf_opt_size=preemie'
export const newborn = c + 'all-products?_=pf&pf_opt_size=newborn'
export const zero_three =
    c + 'all-products?_=pf&pf_opt_size=0-3m&pf_opt_size=0-6m'
export const three_six =
    c + 'all-products?_=pf&pf_opt_size=3-6m&pf_opt_size=0-6m'
export const zero_six = c + 'all-products?_=pf&pf_opt_size=0-6m'
export const six_nine =
    c + 'all-products?_=pf&pf_opt_size=6-9m&pf_opt_size=6-12m'
export const six_twelve = c + 'all-products?_=pf&pf_opt_size=6-12m'
export const nine_twelve =
    c + 'all-products?_=pf&pf_opt_size=9-12m&pf_opt_size=6-12m'
export const twelve_eighteen = c + 'all-products?_=pf&pf_opt_size=12-18m'
export const eighteen_twentyfour = c + 'all-products?_=pf&pf_opt_size=18-24m'

/* Baby Gender */
export const baby_boy =
    c + 'baby-clothes?_=pf&pf_opt_gender=Boy&pf_opt_gender=Unisex'
export const baby_girl =
    c + 'baby-clothes?_=pf&pf_opt_gender=Girl&pf_opt_gender=Unisex'

export const baby_unisex = c + 'baby-clothes?_=pf&pf_opt_gender=Unisex'

/* Baby Accessories */
export const baby_accessories = c + 'baby-accessories'
export const sleep_bags = c + 'sleep-bags-swaddles'
export const baby_blankets = c + 'baby-blankets-swaddles'
export const baby_burp_cloths = c + 'baby-burp-cloths-bibs'
export const baby_hats = c + 'baby-hats-headbands'
export const baby_booties = c + 'organic-baby-booties'
export const baby_socks = p + 'stripe-tube-socks-2-pack'
export const sunglasses = c + 'sunglasses'

/* Baby Gifts */
export const baby_gifts = c + 'baby-gifts'
export const cuddle_box = c + 'hospital-cuddle-box'
export const modern_layette = c + 'modern-layette-newborn-clothing'
export const baby_shower = c + 'baby-shower'
export const baby_hospital = c + 'hospital-coming-home-outfits'
export const baby_first = c + 'babys-first-birthday-box'
export const baby_first_year = c + 'babys-first-year-box'
export const first_foods_box = c + 'babys-first-foods-nurture-box'
export const lets_dance_bundle = c + 'lets-dance-dress-bundle'
export const zipper_bundle = p + 'essential-baby-zipper-bundle'
export const muslin_swaddle =
    p + 'cotton-muslin-swaddle-3-pack?variant=39641221267518'
export const baby_boxes = c + 'baby-boxes-layettes'
export const preg_box = p + 'pregnancy-announcement-box'
export const gender_box = p + 'gender-reveal-box'
export const hospital_gifts = c + 'hospital-homecoming-gifts'
export const towels = c + 'towels-washcloths'
export const baby_shower_gifts = c + 'baby-shower-gifts'
export const snuggle_box = c + 'snuggle-cuddle-box'
export const first_birthday_gifts = c + 'first-birthday-gifts'
export const birthday_print = c + 'happy-birthday-print'

/* Toddler */
export const toddler_clothes = c + 'toddler-clothes'
export const toddler_new = c + 'toddler-new-arrivals'
export const toddler_new_arrivals = c + 'toddler-new-arrivals'
export const toddler_dresses = c + 'toddler-dresses'
export const toddler_dresses_rompers = c + 'toddler-dresses-rompers'
export const toddler_shirts = c + 'toddler-shirts-tops-t-shirts'
export const toddler_pants = c + 'toddler-pants-leggings'
export const toddler_jackets = c + 'toddler-jackets-outerwear'
export const toddler_sweaters = c + 'toddler-sweaters-sweatshirts'
export const toddler_swimsuits = c + 'toddler-swimsuits'
export const toddler_swimwear = c + 'toddler-swimwear'
export const toddler_shorts = c + 'toddler-shorts'
export const toddler_rompers = c + 'toddler-rompers-bodysuits'
export const toddler_bundles = c + 'toddler-outfits-bundles'
export const toddler_accessories = c + 'toddler-kids-accessories'
export const toddler_underwear = c + 'toddler-kids-underwear'
export const toddler_pajamas = c + 'toddler-pajamas'
export const toddler_blankets = c + 'toddler-blankets'
export const toddler_sizes =
    c +
    'all-products?_=pf&pf_opt_size=2T&pf_opt_size=3T&pf_opt_size=4T&pf_opt_size=5T'
export const two_t = c + 'all-products?_=pf&pf_opt_size=2T'
export const three_t = c + 'all-products?_=pf&pf_opt_size=3T'
export const four_t = c + 'all-products?_=pf&pf_opt_size=4T'
export const five_t = c + 'all-products?_=pf&pf_opt_size=5T'

/* Toddler Gender */
export const toddler_boy =
    c + 'toddler-clothes?_=pf&pf_opt_gender=Boy&pf_opt_gender=Unisex'
export const toddler_girl =
    c + 'toddler-clothes?_=pf&pf_opt_gender=Girl&pf_opt_gender=Unisex'

export const toddler_unisex = c + 'toddler-clothes?_=pf&pf_opt_gender=Unisex'

/* Toddler Moments */
export const toddler_play = c + 'developmental-play'
export const toddler_guide = pg + 'shop-toddler-kids'
export const starting_school = c + 'school-ready'
export const playtime = c + 'playtime'

/* Kids */
export const kids_clothes = c + 'kids-clothes'
export const kids_new = c + 'kids-new-arrivals'
export const kids_new_arrivals = c + 'kids-new-arrivals'
export const kids_dresses = c + 'kids-dresses'
export const kids_dresses_rompers = c + 'kids-dresses-rompers'
export const kids_shirts = c + 'kids-shirts-tops-tshirts'
export const kids_pants = c + 'kids-pants-leggings'
export const kids_jackets = c + 'kids-jackets-outerwear'
export const kids_sweaters = c + 'kids-sweaters-sweatshirts'
export const kids_swimwear = c + 'kids-swimwear'
export const kids_shorts = c + 'kids-shorts'
export const kids_rompers = c + 'kids-rompers-bodysuits'
export const kids_bundles = c + 'kids-outfits-bundles'
export const kids_pajamas = c + 'kids-pajamas'
export const kids_accessories = c + 'toddler-kids-accessories'
export const kids_blankets = c + 'toddler-kids-blankets'
export const birthday_boxes = c + 'kids-birthday-boxes'
export const kid_mask = p + 'kid-mask'
export const kids_underwear = c + 'toddler-kids-underwear'

/* Kids Moments */
export const kids_moments = c + 'kids-moments'
export const kids_birthday = c + 'kids-birthday'
export const kids_trips = c + 'kids-trips'
export const potty_training = c + 'potty-training'
export const daycare = c + 'daycare'
export const kids_holiday = c + 'kids-holiday'
export const kids_school = c + 'kids-school'
export const kids_birthday_holiday = c + 'kids-birthday-holiday'

/* Kids Sizes */
export const kids_sizes =
    c +
    'all-products?_=pf&pf_opt_size=2T&pf_opt_size=3T&pf_opt_size=4T&pf_opt_size=5T&pf_opt_size=6&pf_opt_size=7&pf_opt_size=8&pf_opt_size=10&pf_opt_size=12&pf_opt_size=14'
export const kids_sizes_2t = c + 'all-products?_=pf&pf_opt_size=2T'
export const kids_sizes_3t = c + 'all-products?_=pf&pf_opt_size=3T'
export const kids_sizes_4t = c + 'all-products?_=pf&pf_opt_size=4T'
export const kids_sizes_5t = c + 'all-products?_=pf&pf_opt_size=5T'
export const kids_sizes_6_7 = c + 'all-products?_=pf&pf_opt_size=6%2F7'
export const kids_sizes_8 = c + 'all-products?_=pf&pf_opt_size=8'
export const kids_sizes_10 = c + 'all-products?_=pf&pf_opt_size=10'
export const kids_sizes_12 = c + 'all-products?_=pf&pf_opt_size=12'
export const kids_sizes_14 = c + 'all-products?_=pf&pf_opt_size=14'

export const kids_guide = pg + 'shop-toddler-kids'

/* Kids Gender */
export const kids_boy =
    c + 'kids-clothes?_=pf&pf_opt_gender=Boy&pf_opt_gender=Unisex'
export const kids_girl =
    c + 'kids-clothes?_=pf&pf_opt_gender=Girl&pf_opt_gender=Unisex'

export const kids_unisex = c + 'kids-clothes?_=pf&pf_opt_gender=Unisex'

/* Shops */
export const sweats_shop = c + 'the-sweats-shop'
export const oxford_shop = c + 'the-oxford-shop'

/* Mom */
export const loungewear = c + 'organic-loungewear-robes'
export const robes = c + 'organic-lounge-robes'
export const gifts_for_mom = c + 'gifts-for-mom'
export const mommy_me = c + 'mommy-me-and-daddy-me-outfits'
export const accessories = c + 'accessories'
export const adult_mask = p + 'adult-mask'
export const maternity_dresses = c + 'maternity-dresses'
export const hospital_essentials = c + 'hospital-essentials'
export const postpartum_essentials = c + 'postpartum-essentials'
export const loungewear_robes = c + 'loungewear-robes'
export const andie_swim = c + 'andie-swim-monica-andy'

/* Maternity */
export const maternity = c + 'maternity-clothes'
export const maternity_new_arrivals = c + 'maternity-new-arrivals'
export const trimester_2_3 = c + 'trimesters-2-3'
export const maternity_guide = c + 'shop-maternity'
export const book_club = c + 'book-club'

/* Maternity Moments */
export const postpartum = c + 'postpartum'
export const pregnancy = c + 'pregnancy'

/* Blog */
export const cat_pregnancy = bt + 'pregnancy'
export const cat_getting_pregnant = bt + 'getting-pregnant'
export const cat_prenatal_health = bt + 'prenatal-health'
export const cat_maternity_style = bt + 'maternity-style'
export const cat_giving_birth = bt + 'giving-birth'

export const cat_health_wellness = bt + 'health-wellness'
export const cat_moms_health = bt + 'moms-health'
export const cat_baby_kids_health = bt + 'baby-kids-health'

export const cat_ages_stages = bt + 'ages-stages'
export const cat_newborns = bt + 'newborns'
export const cat_toddlers = bt + 'toddlers'
export const cat_kids = bt + 'kids'

export const cat_gear_stuff = bt + 'gear-stuff'
export const cat_registry_101 = bt + 'registry-101'
export const cat_hospital_bag = bt + 'whats-in-myhospital-bag'

export const cat_in_real_life = bt + 'in-real-life'
export const cat_real_mom_features = bt + 'real-mom-features'
export const cat_moms_the_word = bt + 'moms-the-word'
export const cat_podcasts = bt + 'podcast'

/* Nursery */
export const nursery_more = pg + 'shop-all-nursery-more'
export const nursery = c + 'nursery'
export const nursery_gear = c + 'nursery-gear'
export const nursery_furniture_decor = c + 'nursery-furniture-decor'
export const nursery_new = c + 'nursery-new-arrivals'
export const developmental_toys = c + 'developmental-toys'
export const nursery_essentials = c + 'nursery-essentials'
export const nursery_accessories = c + 'nursery-accessories'

export const cribs = c + 'cribs'
export const cribs_bassinets = c + 'cribs-bassinets'
export const decor = c + 'nursery-furniture-decor'
export const bedding = c + 'baby-bedding'
export const furniture = c + 'nursery-furniture'

export const gear_travel = c + 'gear-travel'
export const gear_new = c + 'baby-gear-travel-new-arrivals'
export const gear_guide = pg + 'shop-gear'
export const diaper_bags = c + 'diaper-bags'
export const totes_bags = c + 'totes-bags'
export const changing_pads = c + 'changing-pads'
export const strollers = c + 'strollers'
export const high_chairs = c + 'high-chairs'
export const loungers = c + 'loungers'
export const bouncers = c + 'bouncers'
export const bouncers_loungers = c + 'baby-bouncers-loungers'
export const playards = c + 'playards'
export const carriers = c + 'baby-travel-systems'

export const health_safety = c + 'health-safety'
export const health_safety_essentials = c + 'health-safety-essentials'
export const sound_machines = c + 'sound-machines'
export const scales = p + 'hatch-baby-grow'
export const monitors = c + 'monitors'
export const monitors_sound_machines = c + 'monitors-sound-machines'
export const wellness = c + 'wellness'

export const play_learning = c + 'play-learning'
export const play_gyms = c + 'play-gyms'
export const activity_centers_play_gyms = c + 'activity-centers-play-gyms'
export const baby_toys = c + 'baby-toys'
export const baby_pacifiers = c + 'baby-pacifiers-teethers'
export const bathing = c + 'bathing-grooming'
export const health_wellness = c + 'health-wellness'
export const maternity_health = c + 'maternity-health'

export const nursing_feeding = c + 'nursing-feeding'
export const feeding = c + 'feeding-nursing-eating'
export const baby_feeding =
    c + 'feeding-nursing-eating?_=pf=&pf_pt_product_type=Feeding%20Accessories'
export const nursing_pillows = c + 'nursing-pillows'
export const bottles = c + 'feeding-nursing'
export const sterilizers = c + 'bottle-sterilizers'
export const eating = c + 'eating'
export const plates_cups = c + 'baby-plates-cups'

/* Nursery Moments */
export const building_registry = c + 'building-your-registry'
export const organizing_nursery = c + 'organizing_nursery'
export const development_play = c + 'early-development-play'
export const babys_first_bath = c + 'babys-first-bath'
export const first_aid_essentials = c + 'first-aid-essentials'
export const packing_your_diaper_bag = c + 'packing-your-diaper-bag'
export const setting_up_nursery = c + 'setting-up-your-nursery'

export const nursery_guide = pg + 'shop-all-nursery-more'
export const first_trips = c + 'first-trips'
export const teething = c + 'teething'

/* Gifts */
export const gifts = c + 'gifts'
export const gift_guide = pg + 'gift-guide'
export const gifts_for_baby = c + 'gifts-for-baby'
export const gifts_for_kids = c + 'gifts-for-kids'
export const gifts_for_parents = c + 'gifts-for-parents'

export const just_pregnant = c + 'just-pregnant'
export const holiday_gifts = c + 'holiday-gifts'
export const celebration_gifts = c + 'celebration-gifts'
export const milestone_gifts = c + 'milestone-gifts'
export const baby_shower_welcome_home = c + 'baby-shower-welcome-home'
export const bundle_builder = pg + 'build-your-own-gift-box'
export const books = c + 'baby-books'
export const shop_gifts = pg + 'shop-gifts'

/* Sale */
export const sale_baby =
    c +
    'sale?_=pf&pf_opt_size=preemie&pf_opt_size=newborn&pf_opt_size=0-3m&pf_opt_size=3-6m&pf_opt_size=0-6m&pf_opt_size=6-9m&pf_opt_size=6-12m&pf_opt_size=9-12m&pf_opt_size=12-18m&pf_opt_size=18-24m'

/* Collaborations */
export const collaborations = c + 'characters-shop-all'
export const disney = c + 'disney-monica-andy'
export const lion_king = c + 'disney-lion-king'
export const winnie_the_pooh = c + 'winnie-the-pooh'
export const mickey_minnie = c + 'mickey-minnie-mouse-disney-groovy'
export const disney_halloween = c + 'disney-halloween'
export const disney_princesses = c + 'disney-princess'
export const disney_frozen = c + 'disney-frozen'

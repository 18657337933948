import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavToddler = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_clothes}>Shop All Toddlers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_new}>Toddler New Arrivals</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_shirts}>Shirts + Tops</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_pants}>Pants + Leggings</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_dresses}>Dresses + Rompers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_shorts}>Shorts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_sweaters}>Sweaters + Sweatshirts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_pajamas}>Pajamas</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_swimwear}>Swimwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_jackets}>Jackets + Outerwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_blankets}>Blankets</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toddler_underwear}>Underwear + Socks</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-toddler-sizes mt-2"
                data-subnav="toddler_sizes"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="toddler_sizes"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Size</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_sizes === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-sizes ${
                        activeSubSubnav && activeSubSubnav.toddler_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="toddler_sizes"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.two_t}>2T</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.three_t}>3T</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.four_t}>4T</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.five_t}>5T</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-toddler-gender mt-2"
                data-subnav="toddler_gender"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="toddler_gender"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Gender</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_gender === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-gender ${
                        activeSubSubnav && activeSubSubnav.toddler_gender
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="toddler_gender"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.toddler_boy}>Boy</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.toddler_girl}>Girl</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.toddler_unisex}>Unisex</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-toddler-moments mt-2 mb-3"
                data-subnav="toddler_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="toddler_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moment</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.toddler_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-toddler-moments ${
                        activeSubSubnav && activeSubSubnav.toddler_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="toddler_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.potty_training}>Potty Training</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.starting_school}>Starting School</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.playtime}>Playtime</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.special_occasions}>
                                Special Occasions
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.mommy_me}>Matching Family</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavToddler

import React, { useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { userData } from '@stores/userStore'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import { registryData } from '@stores/registryStore'
import { chord } from '@api/chord.js'
import * as nav from '@data/navdata'

const SubnavAccount = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    const $userData = useStore(userData || null)
    const [haveUser, setHaveUser] = useState(false)

    //

    useEffect(() => {
        if ($userData && $userData.isLoggedIn) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [])

    useEffect(() => {
        if ($userData && $userData.isLoggedIn) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [$userData])

    const handleLogout = () => {
        userData.set()
        localStorage.removeItem('ma_user')
        registryData.set({})
        chord.reset()
        localStorage.setItem('hasRegistry', 'false')
        document.cookie =
            'access_token=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'
        document.cookie =
            'ma_registry_id=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT'
        setTimeout(() => {
            window.location.href = '/'
        }, 1000)
    }

    return (
        <nav className="subnav-wrap" role="navigation">
            <div className="nav-wrap py-2">
                <ul className="nostyle ">
                    <li key="login" className="py-1 text-sm font-normal">
                        {!haveUser ? (
                            <a
                                className="flex items-center"
                                href="/account/login"
                            >
                                <span className="">Login</span>
                            </a>
                        ) : null}
                    </li>
                    <li key="account" className="py-1 text-sm font-normal">
                        {haveUser ? (
                            <a className="flex items-center" href="/account">
                                <span className="">Account Details</span>
                            </a>
                        ) : (
                            <a
                                className="flex items-center"
                                href="/account/register"
                            >
                                <span className="">Create An Account</span>
                            </a>
                        )}
                    </li>
                    {/* <li key="registry" className="py-1 text-sm font-normal">
                        <a
                            className="flex items-center"
                            href="/pages/registry"
                        >
                            <span className="">Registry</span>
                        </a>
                    </li> */}
                    {haveUser ? (
                        <li key="logout" className="py-1 text-sm font-normal">
                            <span className="" onClick={handleLogout}>
                                Log out
                            </span>
                        </li>
                    ) : null}
                </ul>
            </div>
        </nav>
    )
}

export default SubnavAccount

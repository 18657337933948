const Phone = ({ width }) => {
    return (
        <div className={`phone-icon ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.15 265.04">
              <g fill="currentColor">
                <path d="M186.15 265.04H0V0h186.15v265.04zM17.33 247.7h151.48V17.34H17.34V247.7z"></path>
                <path d="M8.67 173.39H177.48999999999998V190.73H8.67z"></path>
                <circle cx="93.08" cy="219.22" r="12.39"></circle>
              </g>
            </svg>
        </div>
    )
}

export default Phone
